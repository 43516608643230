<template>
  <currency-table :items="currencies">
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('currencies')" />
        <v-spacer />
        <currency-dialog
          @load="onLoad"
          @add="onAdd"
          @remove="onDelelete"
          :loading.sync="arLoading"
          v-model="currencyListId"
        />
      </v-toolbar>
    </template>

    <!-- <template #[`item.pivot.credit_limit`]="{ item }">
      <credit-limit-field
        v-model="item.pivot.credit_limit"
        :customer="obCustomer"
        :currency-id="item.id"
      />
    </template> -->
  </currency-table>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import type { DataTableHeader } from "vuetify";
import { Customer } from "@planetadeleste/vue-mc-gw";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";

import CurrencyTable from "@/modules/currencies/components/CurrencyTable.vue";
import CurrencyDialog from "@/modules/currencies/components/CurrencyDialog.vue";
import CreditLimitField from "@/modules/customers/components/CreditLimitField.vue";

@Component({
  components: { CurrencyTable, CurrencyDialog, CreditLimitField },
})
export default class PaymentCurrencyForm extends Vue {
  @VModel({ type: Object, default: () => new Customer() })
  obCustomer!: Customer;

  arCurrencies: CurrencyData[] = [];
  arLoading: number[] = [];
  arHeader: DataTableHeader[] = [
    { text: "credit.limit", value: "pivot.credit_limit", sortable: false },
  ];

  onLoad(arCurrencies: CurrencyData[]) {
    this.arCurrencies = arCurrencies;
  }

  async onAdd(iCurrencyId: number) {
    this.arLoading.push(iCurrencyId);
    let obResponse = null;
    if (this.obCustomer.isNew()) {
      const currencies = this.obCustomer.get("currencies", []);
      const selectedCurrency = this.arCurrencies.find(
        ({ id }) => id === iCurrencyId
      );
      if (
        currencies.filter((_curr: CurrencyData) => _curr.id === iCurrencyId)
          .length === 0
      ) {
        currencies.push(selectedCurrency);
      }
      this.obCustomer.set("currencies", currencies);
    } else {
      obResponse = await this.obCustomer.addCurrency(iCurrencyId);
    }

    if (obResponse) {
      this.obCustomer.set("currencies", obResponse.getData().data);
    }

    const iIdx = this.arLoading.indexOf(iCurrencyId);
    this.arLoading.splice(iIdx, 1);
  }

  async onDelelete(iCurrencyId: number) {
    this.arLoading.push(iCurrencyId);
    let obResponse = null;
    if (this.obCustomer.isNew()) {
      const currencies = this.obCustomer.get("currencies", []);
      const currencyIdx = currencies.indexOf(
        currencies.find((_curr: CurrencyData) => _curr.id === iCurrencyId)
      );
      currencies.splice(currencyIdx, 1);
      this.obCustomer.set("currencies", currencies);
    } else {
      obResponse = await this.obCustomer.delCurrency(iCurrencyId);
    }

    if (obResponse) {
      this.obCustomer.set("currencies", obResponse.getData().data);
    }

    const iIdx = this.arLoading.indexOf(iCurrencyId);
    this.arLoading.splice(iIdx, 1);
  }

  get currencies() {
    return this.obCustomer.get("currencies", []);
  }

  get currencyListId() {
    return this.currencies.length
      ? this.$_.map(this.currencies, (obCurrency) => obCurrency.id)
      : [];
  }

  set currencyListId(arValue: number[]) {
    if (!this.arCurrencies.length) {
      return;
    }

    const arCurrencies = this.$_.filter(this.arCurrencies, (obCurrency) =>
      arValue.includes(obCurrency.id)
    );

    this.obCustomer.currencies = arCurrencies || [];
  }
}
</script>
