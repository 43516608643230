<template>
  <form-field-text
    label="credit.limit"
    input-type="number"
    v-model.number="fValue"
    :loading="loading"
  />
</template>

<script lang="ts">
import type { DebounceFunction } from "@/plugins/helpers";
import type { Customer } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

@Component
export default class CreditLimitField extends Vue {
  @VModel({ type: [Number, String], default: 0 }) fValue!: number;
  @Prop({ type: Object, required: true }) readonly customer!: Customer;
  @Prop(Number) readonly currencyId!: number;

  fnDebounceValue!: DebounceFunction;
  loading = false;

  @Watch("fValue")
  onUpdateValue() {
    this.fnDebounceValue();
  }

  created() {
    this.fnDebounceValue = this.$_.debounce(this.save, 1000);
  }

  async save() {
    if (!this.currencyId || !this.customer) {
      return;
    }

    this.loading = true;
    await this.customer.addCurrency(this.currencyId, this.fValue);
    this.loading = false;
  }
}
</script>
