<template>
  <v-dialog
    scrollable
    max-width="800px"
    transition="dialog-top-transition"
    v-model="dialog"
  >
    <template #activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        {{ $t("edit") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("currencies") }}
      </v-card-title>
      <v-card-text>
        <currency-table :items="arCurrencyList" with-actions>
          <template #[`item.actions`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              :loading="loading.includes(item.id)"
              :dark="getDarken(item.id)"
              :color="getColor(item.id)"
              @click="toggle(item.id)"
            >
              <icon-check-circle size="1.5em" :stroke="2" />
            </v-btn>
          </template>
        </currency-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false" depressed>
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { CurrencyCollection } from "@planetadeleste/vue-mc-shopaholic";
import { Vue, Component, Prop, VModel } from "vue-property-decorator";
import CurrencyTable from "@/modules/currencies/components/CurrencyTable.vue";
import { ConfigModule } from "@/store/config";

@Component({
  components: { CurrencyTable },
})
export default class CurrencyDialog extends Vue {
  @VModel({ type: Array, default: () => [] }) arCurrencyListId!: number[];
  @Prop({ type: Array, default: () => [] }) readonly loading!: number[];

  obCollection: CurrencyCollection = new CurrencyCollection();
  dialog = false;

  get companyCurrencyIdList() {
    return ConfigModule.companyCurrencyIdList;
  }

  get arCurrencyList() {
    return this.obCollection
      .getModelList()
      .filter((obItem) => this.companyCurrencyIdList.includes(obItem.id));
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    await this.obCollection.list();

    this.$emit("load", this.obCollection.models);
  }

  toggle(iValue: number) {
    if (this.arCurrencyListId.includes(iValue)) {
      const iIndex = this.arCurrencyListId.indexOf(iValue);
      if (iIndex > -1) {
        this.arCurrencyListId.splice(iIndex, 1);
        this.$emit("remove", iValue);
      }
      return;
    }

    this.arCurrencyListId.push(iValue);
    this.$emit("add", iValue);
  }

  getColor(iValue: number) {
    return this.$_.isArray(this.arCurrencyListId) &&
      this.arCurrencyListId.includes(iValue)
      ? "green darken-2"
      : "blue-grey lighten-3";
  }

  getDarken(iValue: number): boolean {
    return (
      this.$_.isArray(this.arCurrencyListId) &&
      this.arCurrencyListId.includes(iValue)
    );
  }
}
</script>
